import React, { useState, useEffect } from 'react';

import Header from '../components/Header';
import Navigation from '../components/NavigationMenu';
import About from '../components/About';
import Philosophy from '../components/Philosophy';
import Footer from '../components/Footer';
// import Scroll from '.././utils/smoothScroll';


function AboutUs() {

  const [navbarOpen, setNavbarOpen] = useState(false); 
  const [offset] = useState(0);

  // Detecta cuando el usuario redimensiona la pantalla
  useEffect(() => {
    const resizeHandler = () => {
      if(navbarOpen) {
        document.body.ClientWidth > 1024 ? setNavbarOpen(false) : setNavbarOpen(navbarOpen);
        document.body.ClientWidth > 1024 ? window.location.reload() : window.location.reload(false)
      }
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [navbarOpen]);

  if(!navbarOpen) {
    return (
      <>
        {/* 
          <Scroll />
        */}
        <div className="flex flex-wrap flex-col min-h-screen overflow-hidden">

          {/*  Site header */}
          <Header 
            navbar={navbarOpen} 
            updateNavbar={navbarOpen => setNavbarOpen(navbarOpen)}
            offset={offset} 
            />

          {/*  Page content */}
          <main className="flex-grow">

            {/*  Page sections */}
            <About />
            <Philosophy />

          </main>

          {/*  Site footer */}
          <Footer />
        </div>
      </>
    );
  } else {
    return(
      <>
        <div className="flex flex-col min-h-screen overflow-hidden">

          {/*  Site header */}
          <Header 
            navbar={navbarOpen} 
            updateNavbar={navbarOpen => setNavbarOpen(navbarOpen)} 
            />
          <Navigation
            navbar={navbarOpen}
            />
          
        </div>
      </>
    );
  }
}

export default AboutUs;

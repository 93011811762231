import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../images/logo-ascae.png'


function Header(props) {

  // Valor usando para detectar si se ha hecho scroll 
  const [top, setTop] = useState(true);

  // Detecta cuando el usuario ha hecho scroll mayor a 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed flex flex-wrap w-full z-30 items-center justify-between mb-3 px-2 py-3
      md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white backdrop-blur-sm shadow-lg'}`}>
      <div className={"container flex max-w-6xl mx-auto px-2 sm:px-6" + (props.navbar ? " flex-row" : " flex-wrap")}>
        <div className="flex flex-grow items-start h-16 lg:w-auto lg:block lg:justify-start sm:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 px-3 pb-4 pt-2">
            {/* Logo */}
            <Link to="/" className="block" aria-label="ASCAE Group">
              <img className="ml-5" src={Logo} width="245" height="70" alt="Hero" />
            </Link>
          </div>

          {/* Hamburger button */}
          <div className="flex flex-grow px-3 items-center justify-end lg:hidden">
            <button className="text-black cursor-pointer leading-none px-3 py-5 outline-none focus:outline-none items-center hamburger"
              type="button"
              onClick={() => props.updateNavbar(!props.navbar)}
            >
              <svg className="flex justify-between" width="40" height="40" viewBox="-25 -25 100 100" fill="black" xmlns="http://www.w3.org/2000/svg">
                <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"/>
              </svg>
            </button>
          </div>
        </div>

        {/* Site navigation */}
        <nav id="navbar-menu" className={"lg:flex lg:flex-grow lg:items-center lg:justify-end" + (props.navbar ? " hidden" : " hidden")}>
          <ul className="flex flex-col lg:flex-row justify-end list-none lg:ml-auto flex-wrap items-center">
            <li className="nav-item">
              <Link to="/portafolio" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Portafolio</Link>
            </li>
            <li className="nav-item">
              <Link to="/quienes-somos" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Quiénes Somos</Link>
            </li>
            <li className="nav-item">
              <a href="#contacto" className="btn-sm text-gray-200 bg-blue-900 hover:bg-blue-100 ml-3">
                <span>Contáctanos</span>
                <svg className="w-3 h-3 fill-current text-gray-200 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                </svg>                  
              </a>
            </li>
          </ul>
        </nav>

      </div>
    </header>
  );
}

export default Header;

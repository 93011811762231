import React from 'react';

import AES from "../assets/clientes/AES El Salvador.svg";
import BA from "../assets/clientes/Banco Agricola El Salvador.svg";
import CNJ from "../assets/clientes/CNJ.png";
import ETESAL from "../assets/clientes/ETESAL.png";
import HOLCIM from "../assets/clientes/Holcim El Salvador.svg";
import TIGO from "../assets/clientes/Tigo El Salvador.svg";
import UNILEVER from "../assets/clientes/Unilever.svg";
import UTE from "../assets/clientes/UTE.png";

import ABA from "../assets/clientes/ABAROLI.jpg";
import IJM from "../assets/clientes/IJM.png";
import INL from "../assets/clientes/INL.png";
import OEA from "../assets/clientes/OEA.png";
import WVI from "../assets/clientes/WVI.png";


function Marquee() {
  return(
    <section className="relative">
      {/* Section header */}
      <div className="max-w-3xl mx-auto text-center pt-12 pb-4 md:pb-12 bg-white">
        <h2 className="h2 mb-4">Algunos de Nuestros Clientes</h2>
      </div>

      {/* Section 1 */}
      <div className="relative w-full h-40 mx-0 px-12 sm:px-6 bg-gray-500">
        <ul className="flex h-full py-8 md:py-12 list-none animate-marquee">

          {/* Items */}
          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="70%" src={AES} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="75%" src={BA} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="70%" src={CNJ} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="70%" src={ETESAL} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="70%" src={HOLCIM} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 px-10 max-w-sm">
            <img width="100%" src={TIGO} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 px-10 max-w-sm">
            <img width="100%" src={UNILEVER} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 px-10 max-w-sm">
            <img width="70%" src={UTE} alt="" />
          </li>

        </ul>
      </div>

      {/* Section 1 */}
      <div className="relative w-full h-40 mx-0 px-12 sm:px-6 bg-white">
        <ul className="flex h-full py-8 md:py-12 list-none animate-reverse_marquee">

          {/* Items */}
          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="40%" src={ABA} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="60%" src={IJM} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="35%" src={INL} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="70%" src={OEA} alt="" />
          </li>

          <li className="flex items-center justify-center shrink-0 max-w-sm">
            <img width="60%" src={WVI} alt="" />
          </li>

        </ul>
      </div>

    </section>
  )
}

export default Marquee;
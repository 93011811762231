import React from 'react';
import { Link } from 'react-router-dom';
// import Transition from './Transition';

function Navigation(props) {

  return (
    <div className={'absolute pt-28 w-full h-screen lg:hidden' + (props.navbar ? ' flex' : ' hidden')}>
      {/* Site navigation */}
      <nav id='navigation-menu' className={'flex-grow pl-5 pr-3 pt-8 pb-20 items-center justify-start bg-blue-100 w-full' + (props.navbar ? ' flex' : ' hidden')}>
        <div className='relative ml-20 w-full'>
          <ul className='flex flex-col justify-end list-none flex-wrap items-left'>
            <li className='nav-item'>
              <Link to='/portafolio' className='text-4xl font-medium text-white px-5 py-3 flex items-center transition duration-150 ease-in-out'>Portafolio</Link>
            </li>
            <li className='nav-item'>
              <Link to='/quienes-somos' className='text-4xl font-medium text-white px-5 py-3 flex items-center transition duration-150 ease-in-out'>Quiénes Somos</Link>
            </li>
            <li className='nav-item'>
              <Link to='/contacto' className='btn-sm text-gray-200 bg-blue-900 hover:bg-blue-800 ml-3'>
                <span>Contáctanos</span>
                <svg className='w-3 h-3 fill-current text-gray-200 flex-shrink-0 ml-2 -mr-1' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' fillRule='nonzero' />
                </svg>                  
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;

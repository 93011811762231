import React from 'react';

import TECH from '../assets/ascae/transformacion.svg';
import INNOVATION from '../assets/ascae/innovation.svg';
import DO from '../assets/ascae/do.svg';
import ISO from '../assets/ascae/sg-iso.svg';


function Portfolio() {

  return (
    <section className="relative max-w-6xl mx-auto pt-40 pb-20 px-4 sm:px-6">

      <div className="flex justify-center">
        <h1 className="text-gray-800 h2 mb-6">Portafolio de Servicios</h1>
      </div>

      {/*  */}
      <div className="grid sm:grid-cols-12 gap-8 py-4 md:py-8">

        {/* 3rd block */}
        <div className="sm:col-span-12 lg:col-span-3">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-8 text-center">
                <span className="text-blue-900 font-extrabold">INNOVACIÓN</span>
              </h1>
              <img className='mx-auto' width="60%" src={INNOVATION} alt="" />
            </div>
        </div>

        {/* 1st block */}
        <div className="sm:col-span-12 lg:col-span-3">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-2 text-center">
                  <span className="text-blue-900 font-extrabold">TRANSFORMACIÓN DIGITAL</span>
              </h1>
              <img className='mx-auto pt-6' width="80%" src={TECH} alt="" />
            </div>
        </div>

        {/* 2nd block */}
        <div className="sm:col-span-12 lg:col-span-3">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-2 text-center">
                <span className="text-blue-900 font-extrabold">DESARROLLO ORGANIZACIONAL</span>
              </h1>
              <img className='mx-auto pt-6' width="100%" src={DO} alt="" />
            </div>
        </div>

        {/* 4th block */}
        <div className="sm:col-span-12 lg:col-span-3">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-2 text-center">
                  <span className="text-blue-900 font-extrabold">SISTEMAS DE GESTIÓN</span>
              </h1>
              <img className='mx-auto pt-4' width="60%" src={ISO} alt="" />
            </div>
        </div>
        
      </div>
    </section>
  );
}

export default Portfolio;
import React from 'react';

import SOMOS from '../assets/ascae/ASCAE-Somos.gif';
import HACEMOS from '../assets/ascae/ASCAE-Hacemos.gif';
import LOGRAMOS from '../assets/ascae/ASCAE-Logramos.gif';


function About() {

  return (
    <section className="relative max-w-6xl mx-auto pt-40 px-4 sm:px-6">

      <div className="flex justify-center">
        <h1 className="text-gray-800 h2 mb-4">ASCAE, nos define nuestro nombre</h1>
      </div>

      {/*  */}
      <div className="grid sm:grid-cols-12 gap-8 py-4 md:py-8">

        {/* 1st block */}
        <div className="sm:col-span-12 lg:col-span-4">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-2 text-center">
                  <span className="text-blue-900 font-extrabold">SOMOS</span> una empresa que proporciona a sus clientes:
              </h1>
              <img width="100%" src={SOMOS} alt="" />
            </div>
        </div>

        {/* 2nd block */}
        <div className="sm:col-span-12 lg:col-span-4">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-2 text-center">
                Lo que <span className="text-blue-900 font-extrabold">HACEMOS</span> a través de nuestros servicios:
              </h1>
              <img width="100%" src={HACEMOS} alt="" />
            </div>
        </div>

        {/* 3rd block */}
        <div className="sm:col-span-12 lg:col-span-4">
            <div className="flex flex-col justify-center">
              <h1 className="text-base font-semibold text-gray-800 mb-8 text-center">
                Lo que <span className="text-blue-900 font-extrabold">LOGRAMOS</span> en su organización:
              </h1>
              <img width="100%" src={LOGRAMOS} alt="" />
            </div>
        </div>
        
      </div>
    </section>
  );
}

export default About;
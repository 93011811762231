import React from 'react';

import CALIDAD from '../assets/normas/Calidad.svg';
import RIESGOS from '../assets/normas/Riesgos.svg';
import INNOVATION from '../assets/normas/Innovacion.svg';
import COMPLIANCE from '../assets/normas/Cumplimiento.svg';
import SI from '../assets/normas/Seguridad de la Informacion.svg';
import MA from '../assets/normas/Medio Ambiente.svg';
import GE from '../assets/normas/Gestion Energetica.svg';
import AS from '../assets/normas/Anti Soborno.svg';
import CN from '../assets/normas/Continuidad del Negocio.svg';
import ES from '../assets/normas/Exito Sostenido.svg';
import RS from '../assets/normas/Responsabilidad Social.svg';
import SST from '../assets/normas/Seguridad y Salud en el Trabajo.svg';


function SistemasGestion() {
  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Sistemas de Gestión</h2>
            <p className="text-xl text-gray-600">Un sistema de gestión permite controlar, planificar, organizar y automatizar 
              las tareas administrativas de una organización, analizando los rendimientos y los riesgos de una organización, 
              con el fin de otorgar un ambiente laboral más eficiente y sostenible.
            </p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-3 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="70%" src={CALIDAD} alt="" />
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Calidad</h4>
              <p className="text-gray-600 text-center">
                ISO 9001 <br />
                ISO 17025 <br />
                ISO 17020
              </p>
            </div>

            {/* 2nd item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="70%" src={SI} alt="" />
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Seguridad de la Información</h4>
              <p className="text-gray-600 text-center">ISO 27001</p>
            </div>

            {/* 3rd item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="60%" src={MA} alt="" />
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Medio Ambiente</h4>
              <p className="text-gray-600 text-center">ISO 14001</p>
            </div>

            {/* 4th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="70%" src={SST} alt="" />
              </div>              
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Seguridad y Salud en el Trabajo</h4>
              <p className="text-gray-600 text-center">ISO 45001</p>
            </div>

            {/* 5th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="80%" src={GE} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Gestión Energética</h4>
              <p className="text-gray-600 text-center">ISO 50001</p>
            </div>

            {/* 6th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="80%" src={INNOVATION} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Innovación</h4>
              <p className="text-gray-600 text-center">ISO 56002</p>
            </div>

            {/* 7th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="60%" src={RIESGOS} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Riesgo</h4>
              <p className="text-gray-600 text-center">ISO 31000</p>
            </div>

            {/* 8th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="100%" src={AS} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Antisoborno</h4>
              <p className="text-gray-600 text-center">ISO 37001</p>
            </div>

            {/* 9th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="65%" src={COMPLIANCE} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Cumplimiento</h4>
              <p className="text-gray-600 text-center">ISO 37301</p>
            </div>

            {/* 10th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="70%" src={RS} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Responsabilidad Social</h4>
              <p className="text-gray-600 text-center">ISO 26000</p>
            </div>

            {/* 11th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="60%" src={ES} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Éxito Sostenido</h4>
              <p className="text-gray-600 text-center">ISO 9004</p>
            </div>

            {/* 12th item */}
            <div className="relative h-60 flex flex-col items-center text-center p-6 bg-white rounded shadow-xl">
              <div className="flex items-center justify-center shrink-0 bg-blue-900 rounded-full w-16 h-16 p-1 -mt-1 mb-2">
                <img width="70%" src={CN} alt="" />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Continuidad del Negocio</h4>
              <p className="text-gray-600 text-center">ISO 22301</p>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default SistemasGestion;

import React from 'react';

import MISION from '../assets/ascae/mision.svg';
import VISION from '../assets/ascae/vision.svg';
import VALORES from '../assets/ascae/valores.svg';


function Philosophy() {

  return (
    <section className="relative max-w-6xl mx-auto pt-24">

      <div className="flex justify-center">
        <h1 className="text-gray-800 h2 mb-4">Filosofía Empresarial</h1>
      </div>

      {/* 1st block */}
      <div className="grid sm:grid-cols-12 gap-8 py-4 md:py-8">
        <div className="sm:col-span-12 lg:col-span-6 flex justify-center">
          <img width="60%" src={MISION} alt="" />
        </div>

        <div className="sm:col-span-12 lg:col-span-6 pr-12">
            <div className="flex flex-col justify-center">
              <h1 className="text-xl font-semibold text-gray-800 mb-2 text-center">
                  <span className="text-blue-900 font-extrabold">MISIÓN</span>
              </h1>
              <p className="text-lg text-justify text-gray-600 mb-8">Somos una empresa de Consultoría y Capacitación Empresarial, 
                dedicada a ofrecer servicios profesionales especializados y gerenciales, enfocados en propiciar la creación de valor, 
                a través del fortalecimiento de las ventajas competitivas de nuestros clientes, mediante las disciplinas de 
                Innovación Empresarial y transformación digital, Desarrollo Organizacional y Sistemas de Gestión modernos 
                soportados en estándares internacionales.
              </p>
            </div>
        </div>
      </div>

      {/* 2nd block */}
      <div className="grid sm:grid-cols-12 gap-8 py-4 md:py-8">
        <div className="sm:col-span-12 lg:col-span-6 pl-12">
            <div className="flex flex-col justify-center">
              <h1 className="text-xl font-semibold text-gray-800 mb-2 text-center">
                <span className="text-blue-900 font-extrabold">VISIÓN</span>
              </h1>
              <p className="text-lg text-justify text-gray-600 mb-8">Ser una empresa de consultoría y capacitación empresarial reconocida 
                a nivel internacional por la excelencia e innovación de los servicios y productos que contribuyen a la mejora 
                de la competitividad y reputación empresarial de sus clientes.
              </p>
            </div>
        </div>

        <div className="sm:col-span-12 lg:col-span-6 flex justify-center">
          <img width="50%" src={VISION} alt="" />
        </div>
      </div>

      {/* 3rd block */}
      <div className="grid sm:grid-cols-12 gap-8 py-4 md:py-8">
        <div className="sm:col-span-12 lg:col-span-6 flex justify-center">
          <img width="70%" src={VALORES} alt="" />
        </div>

        <div className="sm:col-span-12 lg:col-span-6 pr-12">
          <div className="flex flex-col justify-center">
            <h1 className="text-xl font-semibold text-gray-800 mb-8 text-center">
              <span className="text-blue-900 font-extrabold">VALORES</span>
            </h1>
            <p className="text-lg text-justify text-gray-600 mb-8">Para el logro de nuestra Visión y cumplimiento de nuestra Misión, 
              asumimos los siguientes valores:
            </p>
            <ul className="text-lg text-gray-600 list-disc pl-12">
              <li>
                Enfoque al Cliente
              </li>
              <li>
                Lealtad
              </li>
              <li>
                Innovación
              </li>
              <li>
                Excelencia en lo que hacemos
              </li>
            </ul>
          </div>
        </div>
      </div>

    </section>
  );
}

export default Philosophy;
import React from 'react';


function Desarrollo() {

  return (
    <section className="relative max-w-6xl mx-auto pt-24">

      <div className="flex flex-col justify-center text-center">
        <h1 className="text-gray-800 h2 mb-4">Desarrollo Organizacional</h1>
        <p className="text-xl text-gray-600 mb-8">Se constituye como el instrumento por excelencia como una forma de ver el 
          funcionamiento interno de la organización como vehículo para adaptarse al cambio, en perspectiva al logro de 
          una mayor eficiencia organizacional.
        </p>
      </div>

      {/* 1st block */}
      <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4">
        <div className="sm:col-span-12 lg:col-span-3 flex justify-center">
          <div></div>
        </div>

        <div className="sm:col-span-12 lg:col-span-9 pr-12">
          <div
            className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg"
          >
            <div>
            <div className="font-bold leading-snug tracking-tight mb-1">Gestión de Procesos</div>
            <div className="text-gray-600">Ayudamos a las organizaciones con métodos, técnicas y herramientas para lograr 
              cambios en su gestión de procesos y como una base fundamental para la adopción de Sistemas de Gestión Empresarial.
            </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              {/* 
              <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              */}
            </div>
          </div>
        </div>
      </div>

      {/* 2nd block */}
      <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4">
        <div className="sm:col-span-12 lg:col-span-9 pl-12">
          <div
            className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg"
          >
            <div>
            <div className="font-bold leading-snug tracking-tight mb-1">Diagnóstico Organizacionales</div>
            <div className="text-gray-600">El diagnóstico organizacional es una manera efectiva de ver una organización para 
              determinar las brechas entre el desempeño actual y el deseado, y cómo puede lograr sus objetivos.
            </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              {/* 
              <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              */}
            </div>
          </div>
        </div>

        <div className="sm:col-span-12 lg:col-span-3 flex justify-center">
          <div></div>
        </div>
      </div>

      {/* 3rd block */}
      <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4">
        <div className="sm:col-span-12 lg:col-span-3 flex justify-center">
          <div></div>
        </div>

        <div className="sm:col-span-12 lg:col-span-9 pr-12">
          <div
            className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg"
          >
            <div>
            <div className="font-bold leading-snug tracking-tight mb-1">Planeación Estratégica, Táctica y Operativa</div>
            <div className="text-gray-600">Desarrollamos procesos de planificación estratégica para establecer el rumbo de la 
              organización (misión) y líneas estratégicas a largo plazo para alcanzar que permitan alcanzar su futuro deseado (visión) 
              por medio de objetivos estratégicos medibles.
            </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              {/* 
              <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              */}
            </div>
          </div>
        </div>
      </div>

      {/* 4th block */}
      <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4">
        <div className="sm:col-span-12 lg:col-span-9 pl-12">
          <div
            className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg"
          >
            <div>
            <div className="font-bold leading-snug tracking-tight mb-1">Documentación Organizacional</div>
            <div className="text-gray-600">Fortalecemos la gestión empresarial e institucional con procesos de recopilación y análisis 
              de información con la finalidad de elaborar documentos en función de su estructura normativa y funcional para fortalecer 
              la gestión del conocimiento en la organización.
            </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              {/* 
              <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              */}
            </div>
          </div>
        </div>

        <div className="sm:col-span-12 lg:col-span-3 flex justify-center">
          <div></div>
        </div>
      </div>

      {/* 5th block */}
      <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4">
        <div className="sm:col-span-12 lg:col-span-3 flex justify-center">
          <div></div>
        </div>

        <div className="sm:col-span-12 lg:col-span-9 pr-12">
          <div
            className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg"
          >
            <div>
            <div className="font-bold leading-snug tracking-tight mb-1">Estudios de Clima Organizacional</div>
            <div className="text-gray-600">Aplicamos modelos de clima organizacional a través de la opinión de los empleados, 
              para conocer la percepción general de la organización en un momento concreto y determinar el grado de compromiso y 
              motivación que tienen las personas ante ésta.
            </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              {/* 
              <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              */}
            </div>
          </div>
        </div>
      </div>

      {/* 6th block */}
      <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4">
        <div className="sm:col-span-12 lg:col-span-9 pl-12">
          <div
            className="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg"
          >
            <div>
            <div className="font-bold leading-snug tracking-tight mb-1">Gestión de Proyectos - PMI</div>
            <div className="text-gray-600">Se utiliza la metodología de proyectos bajo el estándar mundial PMBOK del PMI bajo una 
              perspectiva deductiva para gestionar sus oportunidades.
            </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              {/* 
              <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
              </svg>
              */}
            </div>
          </div>
        </div>

        <div className="sm:col-span-12 lg:col-span-3 flex justify-center">
          <div></div>
        </div>
      </div>

    </section>
  );
}

export default Desarrollo;
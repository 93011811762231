import React from 'react';
import { Link } from 'react-router-dom';

import QR from "../assets/CodigoQRQuejas.png";


function Footer() {
  return (
    <footer>
      <div id='contacto' className="max-w-6xl mx-auto pt-6 px-4 sm:px-6">

        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="flex md:justify-start sm:justify-center mb-6">
              {/* Logo */}
              <Link to="/" className="inline-block" aria-label="ASCAE">
                <svg width="80" height="80" viewBox="0 0 194 194" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M97 90L83.5 112.5H110.5L97 90Z" fill="#00629B" stroke="#00629B"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M97 59L56 129.5H138L97 59ZM83 112.5L97 88.5L111.5 112.5H83Z" fill="#007DBA" stroke="#007DBA"/>
                  <path d="M44 1H64L81 31.5L1 170V135L61 31.5L44 1Z" fill="#007DBA" stroke="#007DBA"/>
                  <path d="M1 135L60.5 31.5L43.5 1H1V135Z" fill="#00629B" stroke="#00629B"/>
                  <path d="M96 1L170 130H193V112H181L117 1H96Z" fill="#007DBA" stroke="#007DBA"/>
                  <path d="M117.5 1H193V111.5H181.5L117.5 1Z" fill="#00629B" stroke="#00629B"/>
                  <path d="M20 193L40 158H193V171H51L39 193H20Z" fill="#007DBA" stroke="#007DBA"/>
                  <path d="M39.5 193L51 172L193 171.5V193H39.5Z" fill="#00629B" stroke="#00629B"/>
                </svg>
              </Link>
            </div>
            <div className="flex flex-col text-sm text-gray-600">
              <Link to="/quienes-somos" className="text-gray-600 hover:text-gray-900 mb-4 hover:underline transition duration-150 ease-in-out">Quiénes Somos</Link>
              {/* 
                <Link to="#" className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Contáctanos</Link>
              */}
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-800 font-medium mb-2">Portafolio</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to="/portafolio" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Innovación</Link>
              </li>
              <li className="mb-2">
                <Link to="/portafolio" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Transformación Digital</Link>
              </li>
              <li className="mb-2">
                <Link to="/portafolio" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Desarrollo Organizacional</Link>
              </li>
              <li className="mb-2">
                <Link to="/portafolio" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Sistemas de Gestión</Link>
              </li>                           
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-800 font-medium mb-2">Oficinas</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <p className="text-gray-600 hover:text-gray-900">El Salvador</p>
                <p className="text-gray-600 hover:text-gray-900">Santa Tecla, La Libertad</p>
                <p className="text-gray-600 hover:text-gray-900">(+503) 2288-1616</p>
                <br />
              </li>
              <li className="mb-2">
                <p className="text-gray-600 hover:text-gray-900">Guatemala</p>
                <p className="text-gray-600 hover:text-gray-900">Zona 10, Ciudad de Guatemala</p>
                <p className="text-gray-600 hover:text-gray-900">(+502) 2244-3945</p>
              </li>
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-800 font-medium mb-2">Tus comentarios nos importan</h6>
            <p className="text-sm text-gray-600 mb-4">Puedes escanear el siguiente código QR para enviar una nueva solicitud respecto a nuestros servicios.</p>
            <div className="flex justify-center mb-4">
              <img width="60%" src={QR} alt="" />
            </div>
            <p className="text-sm text-gray-600">O puedes contáctarnos al correo: <span>info@ascaegroup.com</span></p>
          </div>          

        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <a href="https://www.youtube.com/channel/UCMXksR75YrScEmLHSOce7fQ" 
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out cursor-pointer" 
                aria-label="YouTube"
                target="_blank"
                rel='noreferrer'
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                  <path transform="translate(6, 6)" d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z" />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="https://www.linkedin.com/in/ascae/" 
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out cursor-pointer" 
                aria-label="Linkedin"
                target="_blank"
                rel='noreferrer'
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                  <path transform="translate(6, 6)" d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z"/>
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a href="https://www.facebook.com/ascaegroup" 
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out cursor-pointer" 
                aria-label="Facebook"
                target="_blank"
                rel='noreferrer'
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                </svg>
              </a>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">Todos los derechos reservados.</div>

        </div>

      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import AOS from 'aos';

import './css/style.scss';
import Home from './pages/Home';
import Portafolio from './pages/Portafolio'
import AboutUs from './pages/AboutUs'


function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/portafolio" element={<Portafolio />} />
        <Route exact path="/quienes-somos" element={<AboutUs />} />
      </Routes>
    </>
  );
}

export default App;

import React from 'react';

function Block() {
  return(
    <section className="relative">

      {/* Section header */}
      <div className="max-w-3xl mx-auto text-center pb-8 md:pb-12 bg-white">
        <h2 className="h2 mb-4">Servicios de Consultoría</h2>
        <p className="text-xl text-gray-600">ASCAE ofrece servicios especializados en consultoría basado en las necesidades y 
          expectativas de sus clientes, con un enfoque orientado a resultados y generando valor agregado; por ello brinda 
          soluciones competitivas apoyadas en las metodologías y herramientas de gestión modernas en las siguientes áreas.</p>
      </div>

      {/* Section background */}
      <div className="relative md:mt-0 lg:mt-0 pointer-events-none" aria-hidden="true"></div>

      <div className="relative w-full mx-0 px-12 sm:px-6 bg-blue-900 border-solid border-blue-900 border-2 rounded-t-[48px]">
        <div className="py-12 md:py-20">

          {/* Items */}
          <div className="mac-w-sm mx-4 grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* First Item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded-xl shadow-xl">
              <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="50 -50 396 346" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-blue-100" x="50" y="-65" width="396" height="396" rx="75" />
                  <g strokeWidth="6">
                    <path className="stroke-current text-white" d="M349.112,148H368v-48h-18.888c-2.552-10.848-6.784-21.08-12.632-30.528l13.344-13.352l-33.936-33.936l-13.36,13.336
                      c-9.456-5.848-19.68-10.08-30.528-12.632V4h-48v18.888c-10.848,2.552-21.08,6.784-30.528,12.632L180.12,22.176l-33.936,33.936
                      l13.344,13.352c-5.848,9.456-10.08,19.68-12.632,30.528H128v48h18.888c2.552,10.848,6.784,21.08,12.632,30.528l-13.344,13.352
                      l33.936,33.936l12.256-12.256c-0.096,0.808-0.248,1.6-0.248,2.432c0,7.12,3.76,13.328,9.368,16.88
                      c-0.848,2.224-1.368,4.608-1.368,7.12c0,11.032,8.968,20,20,20h56c11.032,0,20-8.968,20-20c0-2.52-0.52-4.904-1.368-7.12
                      c5.608-3.544,9.368-9.76,9.368-16.88c0-0.736-0.136-1.44-0.216-2.152l11.976,11.976l33.936-33.936l-13.344-13.352
                      C342.328,169.08,346.552,158.848,349.112,148z M276.128,244h-56c-2.2,0-4-1.8-4-4s1.8-4,4-4h56c2.2,0,4,1.8,4,4
                      S278.328,244,276.128,244z M284.128,220h-8h-56h-8c-2.2,0-4-1.8-4-4s1.8-4,4-4h72c2.2,0,4,1.8,4,4S286.328,220,284.128,220z
                        M256.128,146.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24s-24,10.768-24,24c0,10.416,6.712,19.216,16,22.528
                      V196h-16v-21.24l-3.992-2.312C202.864,162.44,192.128,143.88,192.128,124c0-30.872,25.128-56,56-56s56,25.128,56,56
                      c0,19.872-10.736,38.44-28.008,48.448l-3.992,2.312V196h-16V146.528z M248.128,132c-4.416,0-8-3.584-8-8s3.584-8,8-8
                      c4.416,0,8,3.584,8,8S252.544,132,248.128,132z M334.672,138.672c-2.216,13.2-7.264,25.4-14.992,36.248l-3.92,5.504l11.44,11.456
                      l-11.312,11.312l-11.456-11.456l-5.512,3.928c-1.464,1.04-3.064,2.08-4.776,3.112c-1.848-1.08-3.848-1.944-6.016-2.384v-12.6
                      c19.848-13.304,32-35.808,32-59.808c0-39.704-32.296-72-72-72c-39.704,0-72,32.296-72,72c0,23.992,12.152,46.496,32,59.8v12.608
                      c-2.216,0.448-4.256,1.336-6.144,2.456c-1.744-1.056-3.392-2.112-4.896-3.184l-5.512-3.928l-11.456,11.456l-11.312-11.312
                      l11.44-11.456l-3.92-5.504c-7.728-10.848-12.776-23.048-14.992-36.248L160.2,132H144v-16h16.2l1.128-6.672
                      c2.216-13.2,7.264-25.4,14.992-36.248l3.92-5.504L168.8,56.12l11.312-11.312l11.456,11.44l5.504-3.92
                      C207.92,44.6,220.12,39.552,233.32,37.336L240,36.2V20h16v16.2l6.672,1.128c13.2,2.216,25.4,7.264,36.248,14.992l5.504,3.92
                      L315.88,44.8l11.312,11.312l-11.44,11.456l3.92,5.504c7.728,10.848,12.776,23.048,14.992,36.248L335.8,116H352v16h-16.2
                      L334.672,138.672z"/>
                  </g>
                </g>
              </svg>
              <h4 className="text-xl text-center font-bold leading-snug tracking-tight mb-3">Innovación y Transformación Digital</h4>
              <p className="text-gray-600 text-center">Trabajamos en temáticas de: Proyectos de Innovación, Transformación Digital de 
                las Organizaciones, Sistemas de Gestión de Innovación, Investigación Desarrollo e Innovación (I+D+i), Sistemas de 
                Información Gerencial, y herramientas tecnológicas.</p>
            </div>

            {/* Second item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded-xl shadow-xl">
              <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="-4 -4 44 44" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-blue-100" x="-4" y="-4" width="44" height="44" rx="8" />
                  <polygon points="9.8 18.8 26.2 18.8 26.2 21.88 27.8 21.88 27.8 17.2 18.8 17.2 18.8 14 17.2 14 17.2 17.2 8.2 17.2 8.2 21.88 9.8 21.88 9.8 18.8" 
                    className="stroke-current text-white">
                  </polygon>
                  <rect x="2" y="23" width="14" height="10" rx="2" ry="2" className="stroke-current text-white"></rect>
                  <rect x="20" y="23" width="14" height="10" rx="2" ry="2" className="stroke-current text-white"></rect>
                  <rect x="11" y="3" width="14" height="10" rx="2" ry="2" className="stroke-current text-white"></rect>
                  <rect x="0" y="0" width="36" height="36" fillOpacity="0"/>
                </g>
              </svg>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-3">Desarrollo Organizacional</h4>
              <p className="text-gray-600 text-center">Trabajamos en temáticas de: Reingeniería y Gestión de Procesos, Diagnóstico 
                Organizacional, Planeación Estratética, Táctica y Operativa, Gestión de la Mejora, Documentación Organizacional, Estudios 
                de Clima Organizacional y Auditorías de Servicio al Cliente.</p>
            </div>

            {/* Third item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded-xl shadow-xl">
              <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-blue-100" x="0" y="0" width="120" height="120" rx="18" />
                  <g fill="#fff">
                    <path transform='translate(20, 25)' d="M83.076 27.023c-.803-2.108-2.452-3.99-4.459-5.347-5.794-3.679-14.624-3.941-20.594-.615-3.256 1.66-5.396 
                      4.945-6.062 8.328-1.208 5.558-.896 12.171 3.386 16.337 4.993 4.377 12.26 5.344 18.722 3.546.307-.059 1.28-.304 
                      1.951-.66l.189-.081c2.007-.834 3.921-2.192 5.35-4.031 3.298-4.822 3.524-12.047 1.517-17.477M72.555 39.504c-.228 
                      1.054-.937 2.146-2.055 2.542-1.78.783-4.188.566-5.837-.35-1.383-.967-1.561-2.586-1.739-4.118-.135-3.02-.353-6.218.493-8.979.663-1.71 
                      2.492-2.15 4.146-2.279 1.737 0 3.428.265 4.501 1.666.668 1.005.627 2.32.759 3.504-.047 2.495.177 5.471-.268 8.014M19.728 
                      42.224c-1.472-.09-3.121.039-4.546-.136l.044-15.378.09-.128 4.412-.043c.046-2.496.09-5.173-.046-7.448l-16.803-.047-2.767.048c-.044 
                      2.32-.044 5.033 0 7.401 1.517.047 2.941 0 4.413.137v14.893l-.043.612-4.414.09a94.678 94.678 0 0 0 0 7.228h19.659l.001-7.229zM69.208 
                      52.735c-1.87 2.675-4.233 5.259-6.813 7.269-.094.137-.361.085-.446-.041-1.118-.699-2.321-1.271-3.393-1.969 1.428-1.707 2.811-3.415 
                      3.881-5.306H61.05c-1.108 1.627-2.27 3.334-3.74 4.775-1.963-.787-3.745-1.487-5.751-2.017l-.223-.167c.312-.885.89-1.716 
                      1.067-2.593H51.2l-1.203 2.367c-2.627-.569-5.526-.879-8.201-1.052-.135-.393.042-.963-.135-1.315h-1.159c-.092.527.134.924-.134 
                      1.361-2.764.127-5.438.437-8.021.968l-1.208-2.329c-.441 0-.937-.084-1.245.047l1.112 2.585-.133.128a56.26 56.26 0 0 
                      0-5.884 2.062l-.177-.09c-1.251-1.358-2.455-2.841-3.437-4.331-.221-.749-1.113-.311-1.604-.399 1.066 1.93 2.497 3.636 
                      3.921 5.391-1.203.703-2.402 1.487-3.656 2.062a32.138 32.138 0 0 1-7.041-7.404c-.536-.086-.889-.047-1.428 0 3.21 4.994 8.204 9.373 
                      13.42 11.958 0 0 0-.039.048-.039.038 0 .127.081.038.127 3.524 1.709 7.271 2.935 11.235 3.463 13.687 1.794 26.75-4.029 
                      34.324-15.509a7.047 7.047 0 0 0-1.424-.002m-18.585 3.942l.131-.088c1.922.438 3.746 1.052 5.441 1.882-1.428 1.617-3.256 3.022-4.948 
                      4.204-1.024.611-2.053 1.275-3.124 1.797l-1.913-.832.131-.303c1.608-2.145 3.035-4.338 4.282-6.66m-8.785-1.315c2.543.048 5.085.31 
                      7.444.877l.047.133c-1.246 2.363-2.81 4.646-4.501 6.788-.762.09-1.472-.213-2.229-.26l-.762-.09c-.087-2.585-.134-4.863.001-7.448m-8.6.788c2.318-.438 4.724-.788 
                      7.219-.788l.046 7.411c-.938.212-1.963.212-2.855.437-1.828-2.152-3.342-4.475-4.633-6.886l.223-.174zm-7.181 2.368c1.743-.835 
                      3.568-1.452 5.438-1.93l.181.046c1.289 2.41 2.896 4.686 4.5 6.964-.619.353-1.381.564-2.003.92a30.031 30.031 0 0 
                      1-8.115-5.872v-.128zm5.753 7.49c-3.745-.967-7.225-2.631-10.434-4.905v-.136c1.07-.651 2.144-1.354 3.299-1.841 2.543 2.504 5.171 4.388 
                      8.024 6.18-.222.263-.578.527-.889.702m16.537.566c-4.724 1.014-9.987 1.053-14.71-.084-.09-.218.223-.265.356-.393 4.146-2.675 
                      10.607-2.675 14.668.213.132.264-.224.18-.314.264m4.281-1.222l-2.095.655c-.358-.175-.668-.438-.983-.702l.09-.134c2.854-1.658 
                      5.438-3.679 7.891-6.088 1.204.481 2.364 1.142 3.437 1.883-2.499 1.981-5.398 3.289-8.34 4.386M12.908 15.716a41.113 41.113 0 0 1 
                      3.61-4.335c1.155-1.095 2.229-2.146 3.479-2.938 1.16.744 2.451 1.364 3.565 2.062-1.381 1.7-2.808 3.416-3.833 5.251.31.137.892.094 
                      1.339 0 1.113-1.618 2.313-3.238 3.745-4.685 1.912.703 3.787 1.445 5.751 1.973l.173.174-1.11 2.538c.492.094.804.048 1.291 0 
                      .4-.74.71-1.57 1.156-2.319 2.589.572 5.309.877 8.073.962.354.311.088.965.222 1.357h1.203c.131-.43-.045-.918.131-1.308 2.588-.136 
                      5.306-.399 7.811-1.013l.217.046 1.204 2.273c.402 0 .936.094 1.248-.09l-1.117-2.534.093-.088c2.047-.526 3.967-1.314 5.928-2.101 
                      1.386 1.445 2.675 3.153 3.838 4.812.488 0 .979.048 1.336-.09a17.72 17.72 0 0 0-1.918-2.758 18.66 18.66 0 0 
                      0-1.964-2.359v-.227L61.5 8.526l.45-.175a32.558 32.558 0 0 1 7.039 7.312c.535 0 .982.184 1.429-.037C64.438 6.697 54.588.952 
                      43.939.125l-.047.085-.091-.085c-1.202-.129-2.679-.18-3.97-.051-.489-.038-1.067 0-1.648.051C27.486.952 17.635 6.431 11.48 
                      15.758c.446-.042.981.136 1.428-.042m18.503-3.849l-.178.038c-1.83-.477-3.615-1.052-5.309-1.794l.086-.263c2.452-2.321 5.037-4.25 
                      7.89-5.736.672.263 1.34.48 2.008.791l-.131.342c-1.697 2.108-3.031 4.381-4.366 6.622m8.868 1.179c-1.245.175-2.36-.039-3.613-.136-1.198-.176-2.717-.303-3.92-.703 
                      1.293-2.398 2.811-4.724 4.637-6.865l2.854.389c.132 2.274.042 4.904.042 7.315m1.381.047c-.088-2.329-.042-4.91 0-7.32.895-.128 
                      1.871-.264 2.765-.393 1.738 2.187 3.436 4.429 4.596 6.827a31.597 31.597 0 0 1-7.361.886m14.225-3.071c-1.742.741-3.527 1.443-5.441 
                      1.845-1.381-2.322-2.896-4.643-4.595-6.886.625-.343 1.387-.606 2.054-.955 2.903 1.444 5.708 3.501 8.068 5.859l-.086.137zm-5.71-7.49c3.745.966 
                      7.267 2.628 10.477 4.906l-.089.175-3.073 1.706h-.227c-2.359-2.357-5.122-4.416-7.934-6.041.089-.307.537-.528.846-.746m-1.781-.44v.093c-3.881 
                      2.796-10.165 3.06-14.264.475-.23-.175-.67-.26-.718-.567 4.728-1.09 10.297-1.178 14.982-.001M21.199 7.527c2.811-2.057 5.93-3.59 
                      9.23-4.642.135-.137.221-.266.397-.266v.09c.356.086.625-.311.936-.051.269.227.584.4.804.618-2.762 1.705-5.483 3.724-7.98 
                      6.172-1.204-.43-2.314-1.091-3.388-1.835l.001-.086zM40.325 42.566c-4.19 1.141-8.74.271-11.77-2.888-.355-.438-.58-.96-.938-1.396l-6.016.042c-.095 
                      3.547-.137 7.622 0 11.128l6.684-.043.089-2.104c.761.35 1.52.787 2.276 1.18 4.636 1.93 10.604 2.368 15.197 0 3.346-1.835 5.13-5.382 
                      4.638-9.201-.488-3.719-4.056-7.094-7.664-8.011L32.03 28.16l-.798-.396c-.357-.304-.581-.696-.494-1.182.847-1.442 2.811-1.004 
                      4.282-.965 3.12.439 6.329 1.49 8.158 4.339 1.959 0 4.232.038 6.37-.046.091-3.591-.035-6.743-.088-10.38-2.006-.046-4.459-.046-6.462.046-.09.521.086 
                      1.263-.137 1.791l-2.049-1.096c-4.906-2.058-11.772-2.273-16.228.925-2.899 2.099-3.788 5.87-3.03 9.198.577 3.149 3.608 5.473 6.507 
                      6.565 3.967 1.4 8.111 2.414 12.13 3.77.398.178.932.575.846 1.056.002.389-.402.614-.712.781"/>
                  </g>
                </g>
              </svg>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-3">Sistemas de Gestión</h4>
              <p className="text-gray-600 text-center">Nos dedicamos a elaborar Diagnóstico Sistemas de Gestión (SG), 
                Implementación de SG, Auditorías de primera y segunda parte, Acompañamiento procesos Certificación y Acreditación. 
                De similiar forma para Sistemas Integrados de Gestión.
              </p>
            </div>

          </div>
        </div>
      </div>

    </section>
  )
}

export default Block;